import React, { useState, useEffect, useRef } from "react";
import {
  addBannerImage,
  updateBannerImage,
  getBannerById,
} from "../../redux/slices/settingsSlice";
import ReactCrop from "react-image-crop";
import { LineWave } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import backgroundPattern from "../../images/backgroundPattern.svg";

const CreateBanner = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const bannerData = useSelector((state) => state.settingsReducer);
  const [error, setError] = useState({});
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    title: "",
    image: "",
    description: "",
    imageName: "",
  });
  const [imageFile, setImageFile] = useState(null);
  const [crop, setCrop] = useState({
    unit: "px",
    width: 200,
    height: 218,
    x: 0,
    y: 0,
  });
  const [croppedImageFile, setCroppedImageFile] = useState(null);
  const [cropDimensions, setCropDimensions] = useState({
    width: 200,
    height: 218,
  });
  const imageRef = useRef(null);

  useEffect(() => {
    if (croppedImageFile) {
      setFormData((prevState) => ({
        ...prevState,
        image: croppedImageFile,
      }));
    }
  }, [croppedImageFile]);

  useEffect(() => {
    if (id) {
      dispatch(getBannerById(id)).then((res) => {
        const { data } = res?.payload;
        if (data) {
          setFormData({
            title: data?.title,
            imageName: data?.image,
            description: data?.description,
            image: data?.imageUrl,
          });
        }
      });
    }
  }, [id, dispatch]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (!file.type.startsWith("image/")) {
        toast.error("The selected file is not an image.");
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setImageFile(reader.result);
        setFormData((prevState) => ({
          ...prevState,
          imageName: file.name, 
        }));
      };
      reader.readAsDataURL(file);
      setFormData((prevState) => ({
        ...prevState,
        image: e.target.files[0],
        imageName: e.target.files[0].name,
      }));
    }
  };

  const handleCropComplete = (crop) => {
    if (imageRef.current && crop.width && crop.height) {
      const image = imageRef.current;
      const canvas = document.createElement("canvas");
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );
      canvas.toBlob((blob) => {
        if (blob) {
          const file = new File(
            [blob],
            formData.imageName || "cropped-image.jpg",
            {
              type: "image/jpeg",
            }
          );
          setCroppedImageFile(file);
        }
      }, "image/jpeg");

      setCropDimensions({
        width: crop.width,
        height: crop.height,
      });
    }
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    if (formData.title.trim() === "") {
      newErrors.title = "Title is Required";
      valid = false;
    }
    if (formData.description.trim() === "") {
      newErrors.description = "Description is Required";
      valid = false;
    }
    if (!formData.image) {
      newErrors.image = "Image is Required";
      valid = false;
    }

    setError(newErrors);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      const action = id
        ? updateBannerImage({
            id: id,
            body: {
              title: formData.title,
              image: formData.image,
              description: formData.description,
            },
          })
        : addBannerImage(formData);

      dispatch(action)
        .then((res) => {
          if (res?.payload?.code === 200) {
            toast.success(`Banner ${id ? "Updated" : "Added"} Successfully`);
            navigate("/panel/banners");
          } else {
            toast.error("Something Went Wrong");
          }
        })
        .catch((error) => {
          toast.error("Something Went Wrong");
          console.error("Error creating banner:", error);
        });
    }
  };

  return (
    <>
      <div
        className="bg-no-repeat bg-cover py-10 px-14 rounded-xl text-left"
        style={{ backgroundImage: `url(${backgroundPattern})` }}
      >
        <h1 className=" text-white text-[30px]">
          {id ? "Edit" : "Add"} Banner
        </h1>
      </div>
      <div className="bg-[#211849] p-[30px] shadow-sm rounded-[10px] text-left text-white">
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <input
              type="text"
              placeholder="Enter Title"
              value={formData?.title}
              name="title"
              className="mt-1 block w-full text-white px-3 py-2 bg-[#372F5B] rounded-md shadow-sm focus:outline-none focus:ring focus:ring-red-500 focus:ring-opacity-50"
              onChange={handleChange}
            />
            {error.title && (
              <p className="text-red-600 text-[14px] mt-1">{error?.title}</p>
            )}
          </div>
          <div className="mb-4">
            <div className="mt-2 text-sm text-right font-bold text-red-600">
              Suggested Dimensions: 1412px * 218px
            </div>
            <div className="relative mb-4">
              <input
                type="text"
                id="imageText"
                name="imageText"
                placeholder="Upload Image"
                value={formData?.imageName}
                readOnly
                className="mt-1 block w-full px-3 py-2 bg-[#372F5B] rounded-md shadow-sm focus:outline-none focus:ring focus:ring-red-500 focus:ring-opacity-50 text-white"
                required
              />

              <input
                type="file"
                id="image"
                name="image"
                accept="image/*"
                className="hidden"
                onChange={handleImageChange}
              />
              <label
                htmlFor="image"
                className="bg-[gray] text-white font-bold py-1 px-4 rounded focus:outline-none focus:shadow-outline absolute right-2 top-1.5 text-sm cursor-pointer"
              >
                Browse File
              </label>
              {error.image && (
                <p className="text-red-600 text-[14px] mt-1">{error?.image}</p>
              )}
            </div>

            {imageFile && (
              <div className="mb-4">
                <ReactCrop
                  crop={crop}
                  onChange={(_, newCrop) => setCrop(newCrop)}
                  onComplete={handleCropComplete}
                  minHeight={100}
                >
                  <img ref={imageRef} alt="Crop me" src={imageFile} />
                </ReactCrop>
                <div className=" flex items-center justify-between">
                  <div className="mt-2 text-sm text-gray-300">
                    {`Cropped Dimensions: ${Math.floor(
                      cropDimensions.width
                    )}px * ${Math.floor(cropDimensions.height)}px`}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="mb-4">
            <textarea
              name="description"
              rows={5}
              placeholder="Enter Description"
              value={formData?.description}
              onChange={handleChange}
              className="mt-1 block w-full text-white px-3 py-2 bg-[#372F5B] rounded-md shadow-sm focus:outline-none focus:ring focus:ring-red-500 focus:ring-opacity-50"
            ></textarea>
            {error.description && (
              <p className="text-red-600 mt-1 text-[14px]">
                {error?.description}
              </p>
            )}
          </div>

          <button
            type="submit"
            className="bg-red-500 text-white font-bold py-2 px-4 rounded"
          >
            Submit
          </button>
        </form>
        {bannerData?.isLoading && (
          <div className="loader">
            <LineWave
              color={"#fff"}
              loading={bannerData?.isLoading}
              size={150}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default CreateBanner;
