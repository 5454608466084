import { combineReducers } from "@reduxjs/toolkit";
import scenarioReducer from "./slices/scenarioSlice";
import gameReducer from "./slices/gameSlice";
import dashboardReducer from "./slices/dashboardSlice";
import usersReducer from "./slices/usersSlice";
import settingsReducer from "./slices/settingsSlice"
import characterReducer from "./slices/characterSlice";
export default combineReducers({
  scenarioReducer,
  gameReducer,
  dashboardReducer,
  usersReducer,
  settingsReducer,
  characterReducer
});
