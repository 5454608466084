import React, { useState, useEffect } from "react";
import { Field, Form, Formik, ErrorMessage } from "formik";
import { forgotPassword, getUserById } from "../../redux/slices/usersSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { LineWave } from "react-loader-spinner";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const userData = useSelector((state) => state.usersReducer);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    new_password: "",
    email: "",
  });

  const handlePasswordToggle = () => {
    setIsPasswordVisible((prev) => !prev);
  };

  const handleConfirmPasswordToggle = () => {
    setIsConfirmPasswordVisible((prev) => !prev);
  };

  const validationSchema = Yup.object({
    new_password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?!.*\s)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).+$/,
        "Password must contain an uppercase letter, a lowercase letter, a number, and a special character"
      )
      .required("Password is Required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("new_password"), null], "Passwords must match")
      .required("Confirm Password is Required"),
  });

  useEffect(() => {
    if (id) {
      dispatch(getUserById(id))
        .then((res) => {
          setFormData({
            email: res?.payload?.data?.email,
            new_password: "",
          });
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
          toast.error("Error fetching user data.");
        });
    }
  }, [id, dispatch]);

  const handleSubmit = (values, { resetForm }) => {
    dispatch(
      forgotPassword({
        new_password: values?.new_password,
        username: id,
      })
    ).then((res) => {
      if (res?.payload?.code === 200) {
        resetForm();
        toast.success(res?.payload?.message);
        navigate("/panel/users");
      } else {
        toast.error("Something Went Wrong");
      }
    });
  };

  return (
    <div className="max-w-7xl mx-auto mt-8 p-6 bg-[#211849] shadow-md rounded-lg text-left text-white">
      <h1 className="text-2xl font-bold mb-4">Change Password</h1>
      <Formik
        initialValues={formData}
        enableReinitialize={true}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting }) => (
          <Form className="text-left">
            <div className="mb-3">
              <label className="text-white">Email</label>
              <Field
                type="email"
                name="email"
                className="mt-1 text-white block w-full px-3 py-2 bg-[#372F5B] rounded-md shadow-sm focus:outline-none focus:ring focus:ring-red-500 focus:ring-opacity-50"
                readOnly
              />
            </div>
            {!isUpdateMode && (
              <div className="mb-3 relative">
                <label className="text-white" htmlFor="new_password">
                  New Password
                </label>
                <Field
                  type={isPasswordVisible ? "text" : "password"}
                  name="new_password"
                  className="mt-1 text-white block w-full px-3 py-2 bg-[#372F5B] rounded-md shadow-sm focus:outline-none focus:ring focus:ring-red-500 focus:ring-opacity-50"
                />
                <span
                  className="absolute top-[40px] right-2 cursor-pointer"
                  onClick={handlePasswordToggle}
                >
                  {isPasswordVisible ? <FaEye /> : <FaEyeSlash />}
                </span>
                <ErrorMessage
                  name="new_password"
                  component="div"
                  className="text-red-600 text-sm mt-1"
                />
              </div>
            )}
            {!isUpdateMode && (
              <div className="mb-3 relative">
                <label className="text-white" htmlFor="confirmPassword">
                  Confirm Password
                </label>
                <Field
                  type={isConfirmPasswordVisible ? "text" : "password"}
                  name="confirmPassword"
                  className="mt-1 text-white block w-full px-3 py-2 bg-[#372F5B] rounded-md shadow-sm focus:outline-none focus:ring focus:ring-red-500 focus:ring-opacity-50"
                />
                <span
                  className="absolute top-[40px] right-2 cursor-pointer"
                  onClick={handleConfirmPasswordToggle}
                >
                  {isConfirmPasswordVisible ? <FaEye /> : <FaEyeSlash />}
                </span>
                <ErrorMessage
                  name="confirmPassword"
                  component="div"
                  className="text-red-600 text-sm mt-1"
                />
              </div>
            )}
            <button
              className="bg-red-500 text-white font-bold py-2 px-4 rounded"
              type="submit"
              disabled={isSubmitting}
            >
              Change Password
            </button>
          </Form>
        )}
      </Formik>
      {userData?.isLoading && (
        <div className="loader">
          <LineWave color={"#fff"} loading={userData?.isLoading} size={150} />
        </div>
      )}
    </div>
  );
};

export default ChangePassword;
